import React from 'react';
import styles from '../../../components/styles/myme.module.css';

const shopQuestions = [
  {
    question: '初期コストはいくらかかりますか？',
    answer:
      'インターネット回線と、mymeを利用するためのPC・タブレットがあれば初期費用は無料です！',
  },
  {
    question: '毎月の運用コストはいくらかかりますか？',
    answer:
      '初月無料で、2か月目以降のご利用は月額利用料2万円をご負担いただきます。',
  },
  {
    question: 'クレジット決済は使えますか？',
    answer:
      'クレジット決済機能をご希望の場合は、ご相談ください。また、クレジット決済をご希望のお客様は加えて決済手数料が決済毎に発生します。',
  },
  {
    question: 'PCがなくてもmymeは利用できますか？',
    answer: '本システムはPCだけでなく、タブレットでも利用可能です。',
  },
  {
    question: '申し込んでからどのくらいの期間で使えますか？',
    answer:
      'お客様の要件によって変動はありますが、最短3日ほどでご利用いただけます。',
  },
  {
    question: '商品はどうしたら登録できますか？',
    answer:
      '「メニュー設定」のお好きな「カテゴリ」から「商品を追加」ボタンを押すと商品が追加ができます。',
  },
  {
    question: '商品の画像はどうしたら追加できますか？',
    answer:
      '商品情報の入力画面で四角いアイコンをクリックすると画像の選択や変更が可能です。画像を削除したい場合は四角いアイコン直下の「画像を削除する」から削除可能です。',
  },
  {
    question: '商品にサイズやトッピングなどのオプションは付けられますか？',
    answer:
      'サイズやトッピングはもちろん、アレルギー表示など様々な設定が可能です。',
  },
  {
    question: 'デザインの変更はできますか？',
    answer:
      '店内メニューやテイクアウトメニューのヘッダー画像や文字色、背景色が変更可能です。',
  },
  {
    question: '注文の確認はどうすればできますか？',
    answer:
      'テイクアウト予約注文がされると、テイクアウト予約カレンダーへアクセスした際に通知音でお知らせします。カレンダー上では未確認の注文は、注文ステータス「新着」として表示されます。',
  },
  {
    question: '注文のキャンセルがあった場合はどうしたらいいですか？',
    answer:
      'テイクアウト予約のカレンダー上で注文ステータスを「キャンセル」に変更可能です。',
  },
  {
    question: '解約について教えてください。',
    answer:
      '2か月間(うち1か月は利用料無料)のご利用後は、解約の旨のご連絡をいただければいつでも解約が可能です。',
  },
];

const userQuestions = [
  {
    question: 'QRコードが読み込めませんでした。どうしたらいいですか？',
    answer:
      'お手持ちのスマートフォンのカメラの設定を見直していただくか、明るい場所にて再度お試しください。',
  },
  {
    question: '注文した商品の履歴はどこで確認できますか？',
    answer:
      'ご注文内容は、注文者情報入力時のメールアドレスへ送信されます。メールの本文をご確認ください。',
  },
  {
    question: '注文をキャンセルしたいときはどうしたらいいですか？',
    answer: 'お手数ですが、店舗にお電話でお知らせください。',
  },
];

export const Question = () => {
  return (
    <div className={styles.questionWrapper}>
      <p className={styles.questionTitle}>Q＆A（店舗向け）</p>
      {shopQuestions.map((question) => (
        <div key={question.question}>
          <div className={styles.question}>
            <span className={styles.q}>Q</span>
            {question.question}
          </div>
          <div className={styles.answer}>
            <span className={styles.q}>A</span>
            {question.answer}
          </div>
        </div>
      ))}
      <br />
      <p className={styles.questionTitle}>Q＆A（お客様向け）</p>
      {userQuestions.map((question) => (
        <div key={question.question}>
          <div className={styles.question}>
            <span className={styles.q}>Q</span>
            {question.question}
          </div>
          <div className={styles.answer}>
            <span className={styles.q}>A</span>
            {question.answer}
          </div>
        </div>
      ))}
    </div>
  );
};
