import React from 'react';
import styles from '../../../components/styles/myme.module.css';
import adminFlow from '../../../images/myme-lp/adminFlowPC.png';
import adminFlow1 from '../../../images/myme-lp/adminFlowSP1.png';
import adminFlow2 from '../../../images/myme-lp/adminFlowSP2.png';
import adminFlow3 from '../../../images/myme-lp/adminFlowSP3.png';
import userFlow from '../../../images/myme-lp/userFlowPC.png';
import userFlow1 from '../../../images/myme-lp/userFlowSP1.png';
import userFlow2 from '../../../images/myme-lp/userFlowSP2.png';
import userFlow3 from '../../../images/myme-lp/userFlowSP3.png';
import triangle from '../../../images/myme-lp/useFlowNextTriangle.png';

export const UseFlow = () => {
  return (
    <div className={styles.useFlowWrapper}>
      <p className={styles.useFlowTitle}>ご利用の流れ</p>
      <p className={styles.useFlowSubTitle}>店舗</p>
      <img className={styles.useFlowImage} src={adminFlow} alt='' />
      <div className={styles.useFlowImageColumn}>
        <img className={styles.useFlowImageSP} src={adminFlow1} alt='' />
        <img className={styles.useFlowImageSP} src={triangle} alt='' />
        <img className={styles.useFlowImageSP} src={adminFlow2} alt='' />
        <img className={styles.useFlowImageSP} src={triangle} alt='' />
        <img className={styles.useFlowImageSP} src={adminFlow3} alt='' />
      </div>
      <p className={styles.useFlowSubTitle}>テイクアウト利用者</p>
      <img className={styles.useFlowImage} src={userFlow} alt='' />
      <div className={styles.useFlowImageColumn}>
        <img className={styles.useFlowImageSP} src={userFlow1} alt='' />
        <img className={styles.useFlowImageSP} src={triangle} alt='' />
        <img className={styles.useFlowImageSP} src={userFlow2} alt='' />
        <img className={styles.useFlowImageSP} src={triangle} alt='' />
        <img className={styles.useFlowImageSP} src={userFlow3} alt='' />
      </div>
      <p className={styles.useFlowKome}>
        ※その他、mymeの詳しい利用方法は弊社エンジニアや営業が丁寧にご説明します。
      </p>
    </div>
  );
};
