import React from 'react';
import styles from '../../../components/styles/myme.module.css';
import lpHeader from '../../../images/myme-lp/lp_header.png';

export const LPHeader = () => {

  return (
    <div className={styles.header}>
      <img
        src={lpHeader}
        className={styles.headerImage}
        alt='ヘッダー画像'
      />
    </div>
  )
}