import React from 'react';
import styles from '../../../components/styles/myme.module.css';
import { Link } from 'gatsby';
import SEO from '../../../components/Seo';
import strong3 from '../../../images/strong3.png';
import strong4 from '../../../images/strong4.png';
import { LPHeader } from '../../../components/service/myme/LPHeader';
import { Advantages } from '../../../components/service/myme/Advantages';
import { Ability } from '../../../components/service/myme/Ability';
import { ScreenSample } from '../../../components/service/myme/ScreenSample';
import { Question } from '../../../components/service/myme/Question';
import { UseFlow } from '../../../components/service/myme/UseFlow';

const Qrmenu = () => {
  return (
    <>
      <SEO
        title='myme | テイクアウト予約サービス'
        description='テイクアウトをはじめるなら！mymeは飲食店向けのテイクアウト予約サービスです。インターネットからのテイクアウト注文を利用するお客様が増え、売上アップに繋がります。また、予約件数により人件費や食材費の削減を調整することが可能です。何よりお客様をお待たせせずに商品を受け渡しできるので、利用者の満足度向上にも役立てられます。'
      />
      <div className={styles.background}>
        <div className={styles.wrapper}>
          <LPHeader />
          <div className={styles.contents}>
            <Advantages />
            <Ability />
            <UseFlow />
            <ScreenSample />
            <Question />
            <div className={styles.letsContact}>
              <img src={strong3} className={styles.strongContact} alt='' />
              まずはお気軽にお問い合わせください
              <img src={strong4} className={styles.strongContact} alt='' />
            </div>
            <div className={styles.buttonWrapper}>
              <Link
                to={'/contact/'}
                className={`${styles.headerButton} ${styles.headerButtonShadow}`}
              >
                お問い合わせフォーム
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Qrmenu;
