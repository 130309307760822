import React, { useState, useRef } from 'react';
import mymeStyles from '../../../components/styles/myme.module.css';
import adminReservationSlide from '../../../images/myme-lp/admin_reservation_slide.png';
import adminSettingSlide from '../../../images/myme-lp/admin_setting_slide.png';
import userTakeoutSlide from '../../../images/myme-lp/user_takeout_slide.png';
import adminMenuSlide01 from '../../../images/myme-lp/admin_menu_01.png';
import adminMenuSlide02 from '../../../images/myme-lp/admin_menu_02.png';
import userTakeoutSlide01 from '../../../images/myme-lp/user_takeout_01.png';
import userTakeoutSlide02 from '../../../images/myme-lp/user_takeout_02.png';
import userTakeoutSlide03 from '../../../images/myme-lp/user_takeout_03.png';
import userTakeoutSlide04 from '../../../images/myme-lp/user_takeout_04.png';
import userTakeoutSlide05 from '../../../images/myme-lp/user_takeout_05.png';
import adminReservationSlide01 from '../../../images/myme-lp/admin_takeout_01.png';
import adminReservationSlide02 from '../../../images/myme-lp/admin_takeout_02.png';
import adminReservationSlide03 from '../../../images/myme-lp/admin_takeout_03.png';
import adminReservationSlide04 from '../../../images/myme-lp/admin_takeout_04.png';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import 'swiper/swiper-bundle.css';

import Modal from 'react-modal';

SwiperCore.use([Navigation]);
Modal.setAppElement('#___gatsby');

export const ScreenSample = () => {
  const refs = useRef([]);
  refs.current = [];

  const [picExpanded, setPicExpanded] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const clientWidth =
    typeof document !== `undefined` ? document.body.clientWidth : null;

  SwiperCore.use([Pagination]);
  const swiperProps = {
    spaceBetween: 80,
    pagination: {
      clickable: true,
      dynamicBullets: true,
      el: '.swiper-pagination',
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    loop: false,
  };

  const addToRefs = (el) => {
    if (el && !refs.current.includes(el)) {
      refs.current.push(el);
    }
  };

  const achievements = [
    {
      top: true,
      title: 'テイクアウト予約確認フロー',
      thumbnail: adminReservationSlide,
      screenType: 'display',
      images: [
        adminReservationSlide01,
        adminReservationSlide02,
        adminReservationSlide03,
        adminReservationSlide04,
      ],
      description: [
        'テイクアウト予約をカレンダー上で確認できます。',
        '稼働率に応じて、商品準備時間を設定できます。',
        '商品受け渡し可能時間を設定できます。',
        '休業日を設定できます。',
      ],
    },
    {
      top: false,
      title: 'メニュー設定/デザイン設定',
      thumbnail: adminSettingSlide,
      screenType: 'display',
      images: [adminMenuSlide01, adminMenuSlide02],
      description: [
        '商品の画像や価格、アレルゲン等を設定できます。',
        '画像やフォント、色などを自由に変更できます。',
      ],
    },
    {
      top: true,
      title: 'テイクアウト注文フロー',
      thumbnail: userTakeoutSlide,
      screenType: 'mobile',
      images: [
        userTakeoutSlide01,
        userTakeoutSlide02,
        userTakeoutSlide03,
        userTakeoutSlide04,
        userTakeoutSlide05,
      ],
      description: [
        'スマートフォンでテイクアウト予約画面を開きます。',
        '商品をタップすると詳細が確認できます。「カートに入れるボタン」を押すとカートに入ります。',
        'カート内容画面では、カートに追加した商品が一覧で表示されます。',
        'お客様情報など各フォームに入力し、「注文確定ボタン」をタップします。',
        '注文完了後、指定した時刻に、店舗で決済&商品を受け取ります。',
      ],
    },
  ];

  const openModal = (index) => {
    setPicExpanded(index);
    setIsModalOpen(true);
    document.body.setAttribute('style', 'overflow: hidden;');
  };

  const closeModal = () => {
    setPicExpanded('');
    setIsModalOpen(false);
    document.body.removeAttribute('style', 'overflow: hidden;');
  };

  return (
    <div className={mymeStyles.screenSampleWrapper}>
      {achievements.map(
        ({ top, title, thumbnail, screenType, images, description }, index) => {
          return (
            <div
              key={`service-webService-achievements-${title}-${screenType}-${index}`}
            >
              {screenType === 'display' && top ? (
                <p
                  className={`${mymeStyles.title} ${mymeStyles.screenSampleTitle}`}
                >
                  店舗用管理画面
                </p>
              ) : screenType === 'mobile' && top ? (
                <p
                  className={`${mymeStyles.title} ${mymeStyles.screenSampleTitle}`}
                >
                  お客様用オーダー画面
                </p>
              ) : (
                <></>
              )}
              <p
                className={`${mymeStyles.title} ${mymeStyles.screenSampleTitle}`}
              >
                {title}
              </p>
              {!!images && (
                <div
                  className={
                    screenType === 'display'
                      ? mymeStyles.thumnailConatiner
                      : `${mymeStyles.thumnailConatiner} ${mymeStyles.thumnailMobile}`
                  }
                >
                  <img
                    src={thumbnail}
                    alt=''
                    className={
                      screenType === 'display'
                        ? mymeStyles.developmentAchievementThumbnail
                        : mymeStyles.developmentAchievementThumbnailUser
                    }
                    role='presentation'
                    onClick={() => openModal(index)}
                    ref={addToRefs}
                  />
                  {screenType === 'display' ? (
                    <p className={`${mymeStyles.smartPhoneKome}`}>
                      ※スマートフォンをご利用の方は横向きでご覧ください。
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              )}

              {picExpanded !== '' && picExpanded === index && (
                <Modal
                  isOpen={isModalOpen}
                  onRequestClose={closeModal}
                  style={{
                    overlay: {
                      zIndex: 101,
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    },
                    content: {
                      width:
                        screenType === 'display'
                          ? clientWidth > 550
                            ? '60vw'
                            : '70vw'
                          : screenType === 'mobile'
                          ? clientWidth > 550
                            ? '40vw'
                            : '70vw'
                          : '60vw',
                      height:
                        screenType === 'display'
                          ? clientWidth > 550
                            ? '65vh'
                            : '35vh'
                          : screenType === 'mobile'
                          ? clientWidth > 550
                            ? '75vh'
                            : '80vh'
                          : '60vh',
                      top: '50%',
                      left: '50%',
                      margin: '0',
                      padding: '0',
                      transform: 'translate(-50%, -50%)',
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      border: 'rgba(0,0,0,0)',
                    },
                  }}
                >
                  <Swiper className={mymeStyles.swiper} {...swiperProps}>
                    {achievements[picExpanded].images.map((image, index) => (
                      <SwiperSlide
                        className={
                          screenType === 'display'
                            ? mymeStyles.swiperSlide
                            : mymeStyles.swiperSlideUser
                        }
                        key={`service-webService-achievements-${image}-${index}`}
                      >
                        <div className={mymeStyles.slideWindow}>
                          <img src={image} alt='' />
                          <p>{description[index]}</p>
                        </div>
                      </SwiperSlide>
                    ))}
                    <>
                      <div
                        className={`swiper-button-prev  swiper-button-black ${mymeStyles.prevButton}`}
                      />
                      <div
                        className={`swiper-button-next  swiper-button-black ${mymeStyles.nextButton}`}
                      />
                      <div
                        className={`swiper-pagination ${mymeStyles.pagenation}`}
                      />
                    </>
                  </Swiper>
                </Modal>
              )}
            </div>
          );
        }
      )}
    </div>
  );
};
