import React from 'react';
import styles from '../../../components/styles/myme.module.css';
import advantage1 from '../../../images/myme-lp/01_increased_sales.png';
import advantage2 from '../../../images/myme-lp/02_no_waiting_time.png';
import advantage3 from '../../../images/myme-lp/03_low_cost_and_simple.png';
import advantage4 from '../../../images/myme-lp/04_fleshly_made_served.png';
import advantage5 from '../../../images/myme-lp/05_minimal_cooking_.png';
import advantage6 from '../../../images/myme-lp/06_simply_introduced.png';

export const Advantages = () => {
  const meritsLineOne = [
    {
      description:
        'インターネットからのテイクアウト注文を利用するお客様が増え、売上アップに繋がります。mymeはプラットフォームではなく店舗専用のテイクアウトサービスのため、顧客流出もありません。また、Webでテイクアウト予約を受け付けるため、人件費の節約ができます。',
      image: advantage1,
    },
    {
      description:
        'お客様が来店する前にテイクアウト予約を行ってもらうことで、お客様をお待たせすることなく、指定した時刻にスムーズに受け渡しができます。店舗でのお客様の滞在時間が短くなるため、感染症対策にもなります。',
      image: advantage2,
    },
    {
      description:
        '初月の利用料は無料です。発生する料金はシステム月額利用料2万円のみ！売上に応じて金額が増えたりしないため、費用の計算が簡単です。',
      image: advantage3,
    },
    {
      description:
        'お客様の受取時間に合わせて商品の準備ができるので作りたての商品が提供でき、顧客満足度の向上に繋がります。また、食中毒の防止にも効果的です。',
      image: advantage4,
    },
    {
      description:
        '作り置きをせずに、必要な分だけ調理することで在庫リスクや廃棄リスクの軽減ができます。',
      image: advantage5,
    },
    {
      description:
        '導入は、店舗の環境に合わせて当社が一貫してサポートいたします。シンプルで直感的に操作しやすいシステムであるため、簡単にメニューの設定やデザインの変更ができます。',
      image: advantage6,
    },
  ];

  return (
    <div className={styles.meritsWrapper}>
      <div className={styles.meritTitle}>
        <p>
          <span>
            <ruby data-ruby='マイム'>
              myme<rt>マイム</rt>
            </ruby>
            を使うと...
          </span>
        </p>
      </div>
      <div className={styles.merits}>
        {meritsLineOne.map((merit) => (
          <div key={merit.image} className={styles.rectangle}>
            <img src={merit.image} className={styles.meritImage} alt='' />
            <div className={styles.meritDescription}>{merit.description}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
