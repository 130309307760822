import React from 'react';
import styles from '../../../components/styles/myme.module.css';
import grass from '../../../images/myme-lp/grass.png';
import easySettingMenu from '../../../images/myme-lp/easy_setting_menu_popup.png';
import easyDesignLayout from '../../../images/myme-lp/easy_design_layout_popup.png';
import intelligibleReservation from '../../../images/myme-lp/intelligible_reservation_popup.png';
import iPhonesMenuLayout from '../../../images/myme-lp/iPhones_menu_layout.png';
import easySettingOrdertimeHoliday from '../../../images/myme-lp/easy_setting_ordertime_holiday_box.png';
import noticeReservation from '../../../images/myme-lp/notice_reservation_box.png';
import soldoutRealtimeReflect from '../../../images/myme-lp/soldout_realtime_reflect.png';
import menuImage from '../../../images/myme-lp/menu_02.3.png';
import calendarImage from '../../../images/myme-lp/takeout_03.2.png';

const abilities = {
  easySettingMenu: {
    title: '簡単にお店のメニュー設定ができる！',
    description:
      '商品画像や価格のほかにも、アレルギーやトッピング、大盛やサイズといったオプション等、様々なメニュー情報を簡単に設定できます。',
  },
  easyDesignLayout: {
    title: `お客様に表示する画面のデザインを簡単に変更できる！`,
    description:
      'お店の雰囲気とmymeのデザインが合っていない…なんてことはありません。店舗メニューのデザインは任意で変更可能です。店舗のイメージに合わせて、掲載する文字や画像、フォントやフォントサイズ、背景色など様々なカスタマイズが可能です。',
  },
  intelligibleReservation: {
    title: 'カレンダー表示で予約がわかりやすい！',
    description:
      'テイクアウト予約が入ると時系列に沿ってカレンダー上に予約内容が表示されます。テイクアウト商品を受け渡す日時が一目で確認できるので時間の調整がしやすくなります。また、準備中やお渡し済み注文の状態もカレンダー上で管理できます。',
  },
};

export const Ability = () => {
  return (
    <div className={styles.abilityWrapper}>
      <img src={grass} className={styles.grass} alt='pick' />
      <p className={styles.title}>mymeの機能</p>
      <div className={styles.ability}>
        <div className={styles.abilityRow}>
          <p className={styles.abilityTitle}>
            {abilities.easySettingMenu.title}
          </p>
          <img className={styles.abilityImage} src={menuImage} alt='' />
          <img className={styles.abilityBalloon} src={easySettingMenu} alt='' />
          <p className={styles.abilityDescription}>
            {abilities.easySettingMenu.description}
          </p>
        </div>
        <div className={styles.abilityRow}>
          <p className={styles.abilityTitle}>
            {abilities.easyDesignLayout.title}
          </p>
          <img
            className={styles.abilityBalloon}
            src={easyDesignLayout}
            alt=''
          />
          <img
            className={styles.abilityMobileImage}
            src={iPhonesMenuLayout}
            alt=''
          />
          <p className={styles.abilityDescription}>
            {abilities.easyDesignLayout.description}
          </p>
        </div>
        <div className={styles.abilityRow}>
          <p className={styles.abilityTitle}>
            {abilities.intelligibleReservation.title}
          </p>
          <img className={styles.abilityImage} src={calendarImage} alt='' />
          <img
            className={styles.abilityBalloon}
            src={intelligibleReservation}
            alt=''
          />
          <p className={styles.abilityDescription}>
            {abilities.intelligibleReservation.description}
          </p>
        </div>
        <br />
        <p className={styles.title}>他にもこんな機能が！</p>
        <div className={styles.otherAbility}>
          <img
            className={styles.otherAbilityImage}
            src={easySettingOrdertimeHoliday}
            alt=''
          />
          <img
            className={styles.otherAbilityImage}
            src={noticeReservation}
            alt=''
          />
          <img
            className={styles.otherAbilityImage}
            src={soldoutRealtimeReflect}
            alt=''
          />
        </div>
      </div>
    </div>
  );
};
